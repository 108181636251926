<template>
<div>
    <div v-for="(chat, i) in chats" :key="chat.id">
        <div v-show="(((buscar == '') || (chat.nombre.includes(buscar)) || (chat.senderid.includes(buscar) )) && (bandera ? chat.espera : !chat.espera))" @click="activo(i,chat.senderid, chat.session, network)" :id="i" class="list-group-item list-group-item-action chatInactive collapsed sinBorderTop" :href="'#'+ chat.senderid" :aria-controls="chat.senderid" role="button">
            <div class="row pildora">
                <div v-show="chat.tags.consulta" class="col eConsulta" data-toggle="tooltip" data-placement="top" title="Resultado pendiente">
                </div>
                <div v-show="chat.tags.covid" class="col eResultado" data-toggle="tooltip" data-placement="top" title="Estudio COVID-19">
                </div>
                <div v-show="chat.tags.covid_h" class="col eResultado1" data-toggle="tooltip" data-placement="top" title="Horarios Covid">
                </div>
                <div v-show="chat.tags.covid_d" class="col eResultado2" data-toggle="tooltip" data-placement="top" title="Demora Covid">
                </div>
                <div v-show="chat.tags.queja" class="col eQueja" data-toggle="tooltip" data-placement="top" title="Queja">
                </div>
                <div v-show="chat.tags.devoluciones" class="col eEcommerce" data-toggle="tooltip" data-placement="top" title="Devoluciones">
                </div>
                <div v-show="chat.tags.ticketperdido" class="col eTicket" data-toggle="tooltip" data-placement="top" title="Ticket Perdido">
                </div>
                <div v-show="chat.tags.vacante" class="col eRH" data-toggle="tooltip" data-placement="top" title="Vacante">
                </div>
                <div v-show="chat.tags.campanas" class="col elucha" data-toggle="tooltip" data-placement="top" title="Campañas">
                </div>
                <div v-show="chat.tags.facturacion" class="col ecacu" data-toggle="tooltip" data-placement="top" title="Facturacion">
                </div>
                <div v-show="chat.tags.lentes" class="col eLentes" data-toggle="tooltip" data-placement="top" title="Lentes">
                </div>
                <div v-show="chat.tags.callcenter" class="col eCallcenter" data-toggle="tooltip" data-placement="top" title="Call Center">
                </div>
                <div v-show="chat.tags.atendido" class="col eAtendido" data-toggle="tooltip" data-placement="top" title="Atendido">
                </div>
                <div v-show="chat.tags.convenio" class="col eConvenio" data-toggle="tooltip" data-placement="top" title="Ticket Convenio">
                </div>
                <div v-show="chat.tags.spam" class="col eSpam" data-toggle="tooltip" data-placement="top" title="Spam">
                </div>
                 <div v-show="chat.tags.promociones" class="col ePromociones" data-toggle="tooltip" data-placement="top" title="Promociones">
                </div>
                <div v-show="chat.tags.ubicacion" class="col eUbicacion" data-toggle="tooltip" data-placement="top" title="Ubicacion ">
                </div>
                 <div v-show="chat.tags.pyp" class="col ePreciosPreparaciones" data-toggle="tooltip" data-placement="top" title="Precios y Preparaciones ">
                </div>
                 <div v-show="chat.tags.otros" class="col eOtros" data-toggle="tooltip" data-placement="top" title="Otros">
                </div>
                 <div v-show="chat.tags.citas" class="col eCitas" data-toggle="tooltip" data-placement="top" title="No pude agendar mi cita">
                </div>
            </div>
           
            
            <div class="row px-2 listas">
                <div class="circulo d-flex flex-wrap align-content-center" :class="{'bg-whatsapp': network == 'WA'||'WAni', 'bg-messenger': network == 'FB', 'bg-messenger': network == 'GOOGLE'}">
                    <p v-if="chat.network == 'FB'|| network == 'FBni'" class="iconoChat"><i class="icon-messenger-new"></i></p>
                    <p v-else-if="chat.network == 'GOOGLE'" class="iconoChat "> <svg class="opt-go2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 488 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg></p> 
                    <p v-else class="wa"><i class="icon-whatsapp"></i></p>
                </div>
                <span :id="'visto'+i" class="badge nuevosMensajes" :class="{'tiempoChat':((chat.diferencia>3)&&(chat.origen == '2')), 'badge-asesor': chat.origen == 3, 'badge-bot': chat.origen == '1', 'badge-paciente': ((chat.diferencia<3)&&(chat.origen == '2')), 'badge-plataforma': chat.origen == '4', 'badge-warning': chat.origen == '5'}">
                    <p v-if="chat.origen == 1">Bot</p>
                    <p v-else-if="((chat.diferencia>3)&&(chat.origen == 2))">{{Math.round(chat.diferencia)}} min</p> 
                    <p v-else-if="chat.origen == 2">Paciente</p>
                    <p v-else-if="chat.origen == 3">Asesor</p>
                    <p v-else-if="chat.origen == 4">Nuevo</p>
                    <p v-else-if="chat.origen == 5">Traspaso</p>
                    <p v-else>Asignado</p>
                </span>
                <div class="col-10 chats" id="eleccionChat" role="tablist" @click="menuChat(chat.senderid)">
                   
                    <opciones-chat :espera="chat.espera" :tags="chat.tags" :sender="chat.senderid" /> 
                        <p class="mb-1 nombreChat pr-3"><strong>{{chat.nombre}}</strong>  <spam v-show="chat.fijado"><font-awesome-icon icon="thumbtack" rotation="90" size="lg" style="margin-left: 1em;text-align: right;" class="text-info"/></spam>
                        <br> 
                        <span v-if="network == 'FB'|| network == 'FBni'">Nos contactó por Messenger</span>
                        <span v-else-if="network == 'WA'||'WAni'"><strong>{{ network == 'GOOGLE' ? 'Clínica: '+chat.clinica.clinica : chat.numero}}</strong></span>   
                        <br>    
                        <span v-if="chat.origen_tipo == 'INICIO_TEXT'">Ha ingresado un nuevo paciente</span>
                        <span v-else-if="((chat.origen_tipo == 'USER_TEXT')||(chat.origen_tipo == 'ASESOR_TEXT')||(chat.origen_tipo=='ASESOR_MSJ'))" class="text-truncate">{{chat.ultimoMsj}}</span>
                        <span v-else-if="chat.origen_tipo == 'USER_IMAGE'" class="text-truncate">Imagen <font-awesome-icon icon="image" /></span>
                        <span v-else-if="chat.origen_tipo == 'USER_DOCUMENT'" class="text-truncate">Archivo <font-awesome-icon icon="file-alt" /></span>
                        <span v-else-if="chat.origen_tipo == 'USER_VIDEO'" class="text-truncate">Video <font-awesome-icon icon="film" /></span>
                        <span v-else-if="chat.origen_tipo == 'USER_AUDIO'" class="text-truncate">Audio <font-awesome-icon icon="file-audio" /></span>
                        <span v-else-if="chat.origen_tipo == 'USER_LOCATION'" class="text-truncate">Ubicación <font-awesome-icon icon="map-marker-alt" /></span>
                        <span v-else-if="chat.origen_tipo == 'USER_CONTACTS'" class="text-truncate">Contacto <font-awesome-icon icon="address-book" /></span>
                        <span v-else-if="chat.origen_tipo == 'TRASPASO_TRASPASO'" class="text-truncate">Traspaso <font-awesome-icon icon="people-arrows" /></span>
                        <span v-else-if="chat.origen_tipo == 'REGRESO_COLA'" class="text-truncate">El paciente regresó de la cola</span>
                        <span v-else-if="chat.tipoMsj == 9" class="text-truncate">Mensaje no legible<font-awesome-icon icon="times" /></span>
                        <span v-else class="text-truncate">Mensaje desconocido</span>
                    </p>
                </div>
            </div>
            <div  class="row px-2 listas">
                
            </div>
        </div>
    </div>
</div>
</template>

<style>
.opt-go2{
font-size: 25px;
position: relative;
bottom: 2px;
left: 4px;
}
</style>
<script>
import OpcionesChat from './OpcionesChat'
import asesorService  from '../services/asesor'
//import SocketIo from 'socket.io-client'
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faComment,
    faImage,
    faFileAudio,
    faFilm,
    faMapMarkerAlt,
    faFileAlt, 
    faAddressBook,
    faPeopleArrows, 
    faGlobeAmericas
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
library.add(faComment, faImage, faFileAudio, faFilm, faMapMarkerAlt, faFileAlt, faAddressBook,faPeopleArrows, faGlobeAmericas)
import Querys from '../graphQL/chat.graphql'
import ChatService from '../graphQL/chat.service'
export default {
    name: 'Chat',
    components: {
        OpcionesChat,
        'font-awesome-icon': FontAwesomeIcon
    },
    props: {
        buscar: String,
        etiqueta:null
    },
    data() {
        return {
            network: localStorage.getItem('Network'),
            eUbicacion: this.$abrircerrar,
            arregloTotal: 0,
            cargando: false,
            chats: '',
            mensajes: '',
            bandera: false,
            polling: null,
            hiloGet: null,
            espera: 0,
            inbox: 0,
            actualizado:0,
            sender_actual:sessionStorage.getItem('sender_actual'),
            sender_actualID:sessionStorage.getItem('seleccionChat'),
            asesorId: JSON.parse(sessionStorage.getItem('sesion')),
            cola: null
        }
    },
    created() {
        this.$bus.$off('actualiza')
        this.$bus.$on('actualiza', async () => {
            await asesorService.pedirPaciente()
        })
        this.$bus.$off('actualizar-pacientes')
        this.$bus.$on('actualizar-pacientes', () => {
            let sender = sessionStorage.getItem('sender_actual')
            asesorService.finalizarPaciente(sender).then(() => {
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
                this.$bus.$emit('terminar-conversacion')
                this.$bus.$emit('inbox1')
            })
        })
        
        this.$bus.$off('finalizar-pacientes')
        this.$bus.$on('finalizar-pacientes', () => {
            let sender = sessionStorage.getItem('sender_actual')
            asesorService.finalizarPacienteEncuesta(sender).then(() => {
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
                this.$bus.$emit('terminar-conversacion')
                this.$bus.$emit('inbox1')
            })
        })
        this.$bus.$off('despedida-programada')
        this.$bus.$on('despedida-programada', () => {
            let sender = sessionStorage.getItem('sender_actual')
            asesorService.finalizacionProgramada(sender).then(() => {
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
                this.$bus.$emit('terminar-conversacion')
                this.$bus.$emit('inbox1')
            })
        })
        this.$bus.$off('finalizar-px-sinsesion')
        this.$bus.$on('finalizar-px-sinsesion', () => {
            let sender = sessionStorage.getItem('sender_actual')
            asesorService.mandarEspera(sender).then(() => {
                //this.get()
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
                this.$bus.$emit('inbox1')
            })
        })
        this.$bus.$off('espera')
        this.$bus.$on('espera', () => {
        this.bandera = true
        })
        this.$bus.$off('inbox')
        this.$bus.$on('inbox', () => {
            this.bandera = false
        })
        this.$bus.$off('actualizar-chat')
        this.$bus.$on('actualizar-chat', async () => {
            this.chats = []
            this.$bus.$emit('terminar-conversacion')
            this.get()
        })
        this.$bus.$off('actualizar-network')
        this.$bus.$on('actualizar-network', (network) => {
            asesorService.actualizarNetwork(network)
        })

        this.$bus.$off('fijar-pacientes')
        this.$bus.$on('fijar-pacientes',async () => {
            let sender = sessionStorage.getItem('sender_actual')
            await asesorService.fijarPaciente(sender)
        })
        this.get()
    },
    mounted(){
    },
    apollo: {
        $subscribe: {}
    },
    methods: {
        get() {
            this.cargando = true
            this.espera=0
            this.inbox=0
            this.network=localStorage.getItem('Network')
            clearTimeout(this.hiloGet)
            if(this.$apollo.subscriptions.getChatsWA){
                    this.$apollo.subscriptions.getChatsWA.destroy()
            }
            if(this.$apollo.subscriptions.getChatsWANi){
                    this.$apollo.subscriptions.getChatsWANi.destroy()
            }
            if(this.$apollo.subscriptions.getChatsWAHe){
                    this.$apollo.subscriptions.getChatsWAHe.destroy()
            }
            if(this.$apollo.subscriptions.getChatsWAMedicos){
                    this.$apollo.subscriptions.getChatsWAMedicos.destroy()
            }
            if(this.$apollo.subscriptions.getChatsFB){
                    this.$apollo.subscriptions.getChatsFB.destroy()
            }
            if(this.$apollo.subscriptions.getChatsWASa){
                    this.$apollo.subscriptions.getChatsWASa.destroy()
            }
            if(this.$apollo.subscriptions.getChatsWApet){
                    this.$apollo.subscriptions.getChatsWApet.destroy()
            }
            if(this.$apollo.subscriptions.getChatsGoogle){
                    this.$apollo.subscriptions.getChatsGoogle.destroy()
            }
            if(this.$apollo.subscriptions.getChatsWAGml){
                    this.$apollo.subscriptions.getChatsWAGml.destroy()
            }
            if(this.$apollo.subscriptions.getChatsWASoporte){

                    this.$apollo.subscriptions.getChatsWASoporte.destroy()
            }

            if(this.network == 'WA'){
                this.$apollo.addSmartSubscription('getChatsWA', {
                    query: Querys.getChatsWA,
                    
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) { 
                              let response = await ChatService.setupChatWA(data)
                             
                        this.mandarAlChat(response)
                    },
                    error(error) {
                        console.log('Apollo Error '+ error)
                    },
                    graphQLErrors(error) {
                        console.log('Apollo GraphQL Error '+ error)
                    }
                })
            }else if(this.network == 'WAni'){
                this.$apollo.addSmartSubscription('getChatsWANi', {
                    query: Querys.getChatsWANi,
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        let response = await ChatService.setupChatWA(data)
                        this.mandarAlChat(response)
                    },
                })
            }else if(this.network == 'WAhemo'){
                this.$apollo.addSmartSubscription('getChatsWAHe', {
                    query: Querys.getChatsWAHe,
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        let response = await ChatService.setupChatWA(data)
                        this.mandarAlChat(response)
                    },
                    
                })
            }else if(this.network == 'FB'){
                this.$apollo.addSmartSubscription('getChatsFB', {
                    query: Querys.getChatsFB,
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        let response = await ChatService.setupChatWA(data)
                        this.mandarAlChat(response)
                    },
                })
            }else if(this.network == 'WAmedicos'){
                this.$apollo.addSmartSubscription('getChatsWAMedicos', {
                    query: Querys.getChatsWAMedicos,
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        
                        let response = await ChatService.setupChatWA(data)
                        this.mandarAlChat(response)
                    },
                })
            }else if(this.network == 'WApetct'){
                this.$apollo.addSmartSubscription('getChatsWApet', {
                    query: Querys.getChatsWApet,
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        
                        let response = await ChatService.setupChatWA(data)
                        this.mandarAlChat(response)
                    },
                })
            }else if(this.network == 'WAsa'){
                this.$apollo.addSmartSubscription('getChatsWASa', {
                    query: Querys.getChatsWASa,
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        let response = await ChatService.setupChatWA(data)
                        this.mandarAlChat(response)
                    },
                })
            }else if(this.network == 'WAgml'){
                this.$apollo.addSmartSubscription('getChatsWAGml', {
                    query: Querys.getChatsWAGml,
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        let response = await ChatService.setupChatWA(data)
                        this.mandarAlChat(response)
                    },
                })
            }else if(this.network == 'GOOGLE'){
              
                this.$apollo.addSmartSubscription('getChatsGoogle', {
                    query: Querys.getChatsGoogle,
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        let response = await ChatService.setupChatWA(data)  
                        this.mandarAlChat(response) 

                    },
                    error(error) {
                        console.log('Apollo Error '+ error)
                    },
                    graphQLErrors(error) {
                        console.log('Apollo GraphQL Error '+ error)
                    }
                })
            }else if(this.network == 'WAmesa'){ 

                this.$apollo.addSmartSubscription('getChatsWASoporte', {
                    query: Querys.getChatsWASoporte,
                    
                    variables () {
                        return {
                            asesorId: JSON.parse(sessionStorage.getItem('asesorId')),
                        }
                    },
                    async result ({ data }) {
                        let response = await ChatService.setupChatWA(data)
                        this.mandarAlChat(response)
                        //console.log('prueba: '+response)
                    },
                    error(error) {
                        console.log('Apollo Error '+ error)
                    },
                    graphQLErrors(error) {
                        console.log('Apollo GraphQL Error '+ error)
                    }
                })
            }
        },

        mandarAlChat(response) {
  
            var moment = require('moment')
            moment.locale('es')
            // this.chats = this.chats ? this.chats.filter((e) => e.network != this.network) : ''
            this.chats = []
            if(response === '¡No hay pacientes!.'){
                this.$bus.$emit('inbox1', this.inbox)
                return
            }
            response = response.map(chat =>{
                console.log("chat")
                console.log(chat)
                if(chat.etiquetas!=null){
                    console.log("a")
                    const regex = /'/gi;
                    chat.tags= JSON.parse(chat.etiquetas.replace(regex,`"`))
                } else{
                    // chat.tags={"queja":false, "consulta":false, "covid":false, "ecommerce":false, "ticketperdido": false, "rh": false,"OctubreRosa": false, "Cacu": false, "lentes": false, "callcenter": false, "atendido": false, "covid_d": false, "covid_h": false,"convenio":false,"promociones":false }
                    chat.tags={ 'queja': false,'consulta':false,'covid':false,'devoluciones':false,'ticketperdido':false,'vacante':false,'campanas':false,'facturacion': false,'callcenter': false,'atendido':false,'covid_d':false,'covid_h':false,'convenio':false,'promociones':false,'ubicacion':false,'pyp':false,'otros':false,'citas':false,'span':false,'lentes':false  }
                }
                console.log("chat.tags")
                console.log(chat.tags)
                var ms = moment().diff(moment(moment(chat.horaUltimoMsj).toDate()))
                var d = moment.duration(ms)

                if (typeof chat.senderid === 'string') {
                    if(chat.senderid.substr(0,3) == '521'){
                        chat.numero = chat.senderid.substr(3,10)
                    } else {
                        chat.numero = chat.senderid
                    }
                } else {
                    // Manejar el caso en el que chat.senderid no es una cadena de texto
                    chat.numero = chat.senderid
                }

                chat.diferencia=d.asMinutes()
                return chat
            })
            // if(this.chats)
            //     this.chats.push(...response)
            // else
                this.chats = response
                this.chats.sort(function(a, b) {
                if (a.fijado === b.fijado) {
                    return 0; 
                } else if (a.fijado) {
                    return -1; 
                } else {
                    return 1; 
                }
                });
            this.arregloTotal = this.chats.length
            this.$bus.$emit('chatsTotal', this.arregloTotal)
               
            switch (this.etiqueta) {
      
                case 1:
                    this.chats=this.chats.filter(chat=> chat.tags.queja)
                    break;
                case 2:
                    this.chats=this.chats.filter(chat=> chat.tags.consulta)
                    break;
                case 3:
                    this.chats=this.chats.filter(chat=> chat.tags.covid)
                    break;
                case 4:
                    this.chats=this.chats.filter(chat=> chat.tags.ecommerce)
                    break;
                case 5:
                    this.chats=this.chats.filter(chat=> chat.tags.ticketperdido)
                    break;
                case 6:
                    this.chats=this.chats.filter(chat=> chat.tags.rh)
                    break;
                case 7:
                    this.chats=this.chats.filter(chat=> chat.tags.OctubreRosa)
                    break;
                case 8:
                    this.chats=this.chats.filter(chat=> chat.tags.lentes)
                    break;
                case 9:
                    this.chats=this.chats.filter(chat=> chat.tags.callcenter)
                    break;
                case 10:
                    this.chats=this.chats.filter(chat=> chat.tags.atendido)
                    break;
                case 11:
                    this.chats=this.chats.filter(chat=> chat.tags.covid_h)
                    break;
                case 12:
                    this.chats=this.chats.filter(chat=> chat.tags.covid_d)
                    break;
                case 13:
                    this.chats=this.chats.filter(chat=> chat.tags.Cacu)
                    break;
                case 15:
                    this.chats=this.chats.filter(chat=> chat.tags.Tconvenio)
                    break;
                case 16:
                     this.chats=this.chats.filter(chat=> chat.tags.promociones)
                    break;
                default:            
                    break;
            }
            // this.$bus.$emit('pacienteencola')
            const enInbox = this.chats ? this.chats.filter(e => e.espera==false) : []
            const enEspera = this.chats ? this.chats.filter(e => e.espera==true) : []
            this.espera = enEspera.length
            this.inbox = enInbox.length
            this.$bus.$emit('espera1', this.espera)
            this.$bus.$emit('inbox1', this.inbox)
            //this.hiloGet = setTimeout(this.get, 15000)
        },
        
        visto(id) {
            var visto = document.getElementById('visto' + id)
            visto.classList.remove('badge-asesor')
            visto.classList.remove('badge-paciente')
            visto.classList.remove('badge-bot')
            visto.classList.remove('badge-plataforma')
            visto.classList.remove('badge-warning')
            visto.classList.add('badge-secondary')

        },
        activo(id, senderid, sesion) {
            if(sessionStorage.getItem('sender_actual') != senderid){ 
                sessionStorage.setItem('sender_actual', senderid)
                this.desactivar()
                var chatActivo = document.getElementById(id)
                chatActivo.classList.add('chatActive')
                this.vermensajes(senderid)
                this.sendmensaje(senderid)
                this.visto(id)
                this.$bus.$emit('cuidarInput')
                this.$bus.$emit('limpiarRSV', 1)
                sessionStorage.setItem('seleccionChat', id)
                if(this.network == 'GOOGLE'){
                    this.$bus.$emit('sesionPX', true)
                }else{
                    this.$bus.$emit('sesionPX', sesion)
                }
                
                this.$bus.$emit('paciente', this.sender_actual)
                this.$bus.$emit('actualizar-network', this.network)
                this.$bus.$emit('chatAbierto', true)
            }
        },
        desactivar() {
            this.arregloTotal = this.chats.length;
            for (var i = 0; i < this.arregloTotal; i++) {
                var chatInactivo = document.getElementById(i)
                chatInactivo.classList.remove('chatActive')
            }
        },
        vermensajes(senderid) {
            sessionStorage.setItem('sender_actual', senderid)
            this.$bus.$emit('ver-mensajes')
            this.sender_actual = senderid
        },
        async sendmensaje(senderid) {
            await this.$bus.$emit('send-mensajes', senderid)
        },
        menuChat(senderid) {
            sessionStorage.removeItem('sender')
            sessionStorage.setItem('sender', senderid)
        },
    },
    beforeDestroy() {
    this.hiloGet = 0
    delete this.hiloGet
    }
}
</script>

